<template>
  <div>
    <div class="top-nav-login">
      <b-link class="brand-logo" href="/">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 40" class="brand-logo-svg mt-75 ml-5">
            <defs></defs>
            <path id="G" d="M178.65,23.16c-.5.1-.9.2-1.3.3a1.62,1.62,0,0,0-1.3,1.7v5.7a13.59,13.59,0,0,1-2.8,1.1,27.63,27.63,0,0,1-7.5,1,13.33,13.33,0,0,1-9.4-3.9,10.6,10.6,0,0,1-3.1-7.5,11.48,11.48,0,0,1,1-5.5,12.09,12.09,0,0,1,8.7-7.1,14.83,14.83,0,0,1,9.3.8l.9.3a1.43,1.43,0,0,0,2-.8,2.19,2.19,0,0,1,.3-.5c0-.1.1-.1.2-.2h.3a55.38,55.38,0,0,1,.1,8.2,1.09,1.09,0,0,0-.5.1,2.19,2.19,0,0,0-.3-.5,10.8,10.8,0,0,0-1.6-3,8.1,8.1,0,0,0-6.6-3.3,6.63,6.63,0,0,0-4.5,1.5,7.42,7.42,0,0,0-2.3,3.9,17.63,17.63,0,0,0-.7,4,20,20,0,0,0,.7,7.3,6.5,6.5,0,0,0,1.1,2.2,6,6,0,0,0,4.1,2.6,8.64,8.64,0,0,0,5-.5v-6c0-.9-.7-1.5-1.8-1.7a3.4,3.4,0,0,1-1-.1c-.1-.1-.1-.3-.2-.4a.37.37,0,0,1,.3-.1h11C178.65,22.86,178.65,23,178.65,23.16Z" class="path-fill"></path>
            <path id="P" d="M150.85,15a5.52,5.52,0,0,0-3.5-4.9,10.23,10.23,0,0,0-4.2-.9c-3.9-.1-7.8,0-11.7,0-.1,0-.2.1-.3.1,0,.2.1.3.1.4.6.1,1.2.1,1.7.2a1.62,1.62,0,0,1,1.4,1.6v18.4a1.66,1.66,0,0,1-1.6,1.7c-.5.1-1,.1-1.6.2v.5h12v-.5c-.6-.1-1.2-.1-1.8-.2a1.6,1.6,0,0,1-1.4-1.5,2.77,2.77,0,0,1-.1-.9v-6.9a4.33,4.33,0,0,1,.1-.5c1.4-.1,2.8-.1,4.1-.3a8.57,8.57,0,0,0,4.3-1.5A5.25,5.25,0,0,0,150.85,15Zm-5.9,2.6a3.84,3.84,0,0,1-3.5,2.9H140v-10a7.41,7.41,0,0,1,2.1.1c1.8.5,2.8,1.5,3.1,3.8A8.79,8.79,0,0,1,145,17.56Z" class="path-fill"></path>
            <path id="C" d="M128.35,8.66v7.9l-.1.1h-.5a13,13,0,0,0-1.5-3.4,7,7,0,0,0-6.5-3.1,7.15,7.15,0,0,0-6.1,4.3,15.63,15.63,0,0,0-1.2,5.6,18.25,18.25,0,0,0,1,6.6,6.32,6.32,0,0,0,3.5,4,10.55,10.55,0,0,0,2.4.6,8.37,8.37,0,0,0,4.9-1,11.6,11.6,0,0,0,2.1-1.6c.5-.4,1-1,1.6-1.5v2a11.72,11.72,0,0,1-7.2,3.7,14.63,14.63,0,0,1-7.2-.8,10.61,10.61,0,0,1-7-8.4A12.51,12.51,0,0,1,116.15,9a14,14,0,0,1,7.6.5,14.58,14.58,0,0,1,1.9.7,1.41,1.41,0,0,0,2-1,.9.9,0,0,0,.1-.5c.1,0,.2-.1.3-.1S128.15,8.56,128.35,8.66Z" class="path-fill"></path>
            <path id="ny" d="M108.25,9.16c-.1.2-.1.4-.2.5s-.4.1-.6.2a2.61,2.61,0,0,0-1.6,1.1,32.84,32.84,0,0,0-2,3.1c-1.6,2.7-3.3,5.5-4.9,8.2a1.93,1.93,0,0,0-.3,1v6.3c0,1.5.6,2.1,2.2,2.2h1.5c0,.2.1.3.1.4-.2.2-11.2.3-13.1.1v-.5h1.2a5,5,0,0,0,1.2-.2,1.59,1.59,0,0,0,1.2-1.5v-6a1.14,1.14,0,0,0-.3-.8c-2.1-3.8-4.2-7.5-6.3-11.3a14.63,14.63,0,0,0-.9-1.3,2.91,2.91,0,0,0-3.9-.1,3.72,3.72,0,0,0-.7,2v20.1c-.4.3-.6.2-.8-.1l-1.5-1.8c-2.6-3.2-5.2-6.3-7.7-9.5-1.8-2.3-3.7-4.5-5.5-6.8a5,5,0,0,0-.7-.8c0,.2-.1.3-.1.4v14.3a5.85,5.85,0,0,0,.2,1.3,2.25,2.25,0,0,0,1.8,1.7c.5.1,1,.2,1.6.3a.9.9,0,0,1-.1.5h-8.2v-.4a.31.31,0,0,1,.2-.1c.4-.1.7-.1,1.1-.2,1.3-.3,1.8-.8,2.1-2.3a9.7,9.7,0,0,0,.1-1.7V12.76a2.45,2.45,0,0,0-.6-1.6,3.22,3.22,0,0,0-2.8-1.5v-.5c.2-.2,5.4-.2,8.4-.1,1.9,2.3,3.8,4.6,5.6,7s3.7,4.6,5.7,7.1c0-.5.1-.7.1-1v-9.4c0-2.1-.7-2.9-2.9-3.2a.37.37,0,0,1-.3-.1v-.4s15.6-.1,18.1.1v.4c-1.9.4-1.9.4-2.4,1,.2.5.4,1.1.7,1.7,1.2,2.3,2.5,4.6,3.7,6.9.4.7.8,1.4,1.2,2.2.4-.7.8-1.3,1.1-1.9,1.2-2.1,2.5-4.2,3.7-6.3a7.54,7.54,0,0,0,.6-1.3c.4-1,0-1.7-1.1-1.9-.4-.1-.8-.1-1.3-.2a1.09,1.09,0,0,0-.1-.5h7.5Z" class="path-fill"></path>
            <path id="nys" d="M55,35.16a.82.82,0,0,0-1.2-.1c-.1-.3-.3-.7-.4-1-2.5,1.6-1.4,1.4-4.6,1.4h-.4a.75.75,0,0,0-.5.1c-.2.2-.4.4-.5.4h-1.7c-.1,0-.2-.1-.3-.2-.1.1-.2.2-.2.3-.4,0-.7-.1-.9,0a.62.62,0,0,1-.7.2,5.74,5.74,0,0,1-.4.7c-.1-.2-.2-.3-.3-.5a5.72,5.72,0,0,1,.7-.8.94.94,0,0,0,.4-1.4c.4-.3.7-.6,1-.8a4.25,4.25,0,0,1-.2-1.1,17.7,17.7,0,0,1,.1-2.3,13.73,13.73,0,0,0,.2-3.5,5.87,5.87,0,0,1,.4-2.3,9.83,9.83,0,0,1,.5-1.6,8.34,8.34,0,0,0,.6-3.5c0-1.9.1-3.7.2-5.6l-.6-.6c-.3.2-.5.4-.9.7a12.51,12.51,0,0,1,.6-1.8l-.3-1.8a3.09,3.09,0,0,1-.2-.8,2,2,0,0,1,.7-1.5c-.1-1.4-.8-2.5-.7-3.8h.3c0-.2,0-.5.1-.6.3-.3-.2-.5-.1-.7a1.48,1.48,0,0,0,.1-.7,2,2,0,0,1,.1-.7c-.7,0-1.3.1-2,.1-2.7,0-5.4.1-8.1-.1-.1,0-.2.1-.3.1a13.32,13.32,0,0,0-3.9,2.1l-2.1,1.8c-.1.2-.3.4-.3.5.1.6-.5.7-.7,1a5.16,5.16,0,0,1-1.1.2c0,.3.1.4.1.6A8.84,8.84,0,0,0,25.15,9c.2.4.4.7.6,1.1.5-.2.7.2,1,.4-.3.4-.3.4-1.2.6a2.44,2.44,0,0,1,.3,2.8h-1c-1.4.7-2.3,1.9-3.8,2.1a.1.1,0,0,0-.1.1,8.73,8.73,0,0,0-4.3.3,5.54,5.54,0,0,0-2.2-1,1.27,1.27,0,0,1-.6-.1c-.7-.2-1.5.1-2.3-.1-1.9-.3-3.6.4-5.4.9v1.5a.52.52,0,0,0,.4.2c-.1.2-.1.3-.2.5.3.2.5.3.8.5.2.4.3.8.5,1.3a4.63,4.63,0,0,0-2.1,1.7c-.8.5-1.4.8-2,1.2-.4.3-.9.6-1.3.9a8.79,8.79,0,0,0-1,.6v2.2h30.9c.3.4.5.7.8,1.1.5,0,.8.2,1.1.8a7.72,7.72,0,0,1,.2,1.5,8.79,8.79,0,0,1,.6,1,5.64,5.64,0,0,1,1.2.4,3.33,3.33,0,0,1,.6.3,1.94,1.94,0,0,0,1.1.9c.9.5,1.9,1,2.8,1.5.6.3,1.2.7,1.8,1-.1,1.2-1.1,2-.8,3.2a1.4,1.4,0,0,0,1.5.3,29.92,29.92,0,0,0,3.2-.8c.5-.1,1-.3,1.5-.4a3.64,3.64,0,0,1,1.4-.2c.6.1.9-.2,1.4-.4h.3c.4.1.6-.1.9-.3a1.52,1.52,0,0,1,.8-.4c.4.1.6-.1.9-.2,1-.4,1.9-.8,2.8-1.2.1-.1.2-.1.4-.2C56,34.46,55.55,35.06,55,35.16Z" class="path-fill"></path>
        </svg>
      </b-link>
    </div>
    <div class="auth-wrapper auth-v1 px-2">
      
      <!-- <b-row class="auth-inner py-2"> -->
      <b-card no-body class="mb-0 account-body">

        <!-- Brand logo-->
        <!-- <b-link class="brand-logo" href="/">
          <vuexy-logo />
        </b-link> -->
        <!-- /Brand logo-->

        <!-- Left Text-->
        <!-- <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img-lazy
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col> -->
        <!-- /Left Text-->

        <!-- Login-->
        <!-- <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          > -->
          <b-card-body class="p-0 m-0">
            <b-card-title
              class="mb-1 mt-2 font-weight-bold justify-content-between"
              style="text-align: center; font-size: 24px; color: #7f1d1a; font-weight: 500; font-family: inherit;"
              title-tag="h2"
            >
              Welcome to NYCPG Portal
            </b-card-title>
            <b-card-text class="mb-25 text-primary justify-content-between" style="text-align: center;">
              Please sign in
            </b-card-text>

            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="ingresoUsuario({email: userEmail, password})"
              >
                <!-- <b-form-group
                  label="Email"
                  label-for="login-email"
                > -->
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="Email Address"
                      tabindex="1"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                <!-- </b-form-group> -->

                <!-- <b-form-group> -->
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                        tabindex="2"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                <!-- </b-form-group> -->

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  tabindex="3"
                  class="mt-1"
                >
                  Sign in
                </b-button>

                <div class="mt-1 mb-50" style="text-align: center;">
                  <!-- <label for="login-password">Password</label> -->
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot password</small>
                  </b-link> • 
                  <b-link href="mailto:portalhelp@nycpg.org">
                    <small>Contact us</small>
                  </b-link> • 
                  <b-link v-b-modal.modal-1>
                    <small>Need Help?</small>
                  </b-link>
                </div>
                <div class="mt-0 mb-2" style="text-align: center;">
                  <!-- <label for="login-password">Password</label> -->
                  <b-link :to="{name:'terms-of-use'}">
                    <small>Terms of Use</small>
                  </b-link> • 
                  <b-link :to="{name:'privacy-policy'}">
                    <small>Privacy Policy</small>
                  </b-link>
                </div>
              </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2 mb-2 text-card-register">
              <span>New to NYCPG? </span>
              <b-button
                type="submit"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="ml-1"
                style="padding: 10px;"
                :to="{name:'auth-register'}"
              >
                Create an account
              </b-button>
            </b-card-text> -->

            <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div> -->

            <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="google"
                style="background: #f0f1f1; color: #676b85 !important; font-weight: bold; padding: 10px 30px !important; border-radius: 20px;"
                @click="google"
              >
                <b-img
                  :src="require('@/assets/images/icons/google.png')"
                  style="height: 20px; margin-right: 10px;"
                />
                Sign in with Google 
              </b-button>
            </div> -->
          </b-card-body>
          <!-- </b-col>
        </b-col> -->
      <!-- /Login-->
      </b-card>
      <!-- </b-row> -->
      <b-modal
        id="modal-1"
        title="Need Help?"
        hide-footer
      >
        <b-card-text>
          <!-- <h5>Check First Paragraph</h5> -->
          <p>For questions or more information, please contact:</p>
          <span>Email: <span class="text-primary">portalhelp@nycpg.org</span></span><br>
          <!-- Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
          Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice. -->
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BImgLazy, BCard, BCardBody, BModal, VBModal,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { firebase, auth, db } from '@/firebase'
import { mapActions, mapState, mapMutations } from "vuex"
import moment from 'moment'
import router from '@/router'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BImgLazy,
    BCard,
    BCardBody,
    BModal,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Login' }
    ]
  },
  computed: {
    ...mapState(['usuario']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapMutations(['nuevoUsuario']),
    google() {
        const provider = new firebase.auth.GoogleAuthProvider()
        this.ingresar(provider)
    },
    async ingresar(provider) {
      // ingreso con cuenta de google
      firebase.auth().languageCode = 'en';

      try {
        // Ingreso Usuario
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;

        // Construir usuario
        const usuario = {
          nombre: user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
          role: 'clinician',
          ability: [ 
            {
              action: 'manage',
              subject: 'all'
            }
          ]
        }

        this.nuevoUsuario(usuario)

        // Guardar el usuario
        await db.collection('usuarios').doc(usuario.uid).set(
          usuario
        )

        // obtener ruta
        this.$ability.update(usuario.ability)
        this.$router.replace(getHomeRouteForLoggedInUser(usuario.role))
      } catch (error) {
        console.log(error);
      }
    },
    async ingresoUsuario(usuario) {
      // ingreso con email y contraseña
      try {
        const signIn = await auth.signInWithEmailAndPassword(usuario.email,usuario.password)
        
        const doc = await db.collection('usuarios').doc(signIn.user.uid).get()

        // el usuario ya existe
        if(doc.exists) {
          // console.log(doc.data())
          // login condition
          if(doc.data().role === 'clinician' || doc.data().role === 'clinician-staff') {
            if(doc.data().role === 'clinician-staff') {
              if(doc.data().dependency) {
                db.collection('usuarios').doc(doc.data().dependency).get()
                .then(itemClinician => {
                  var programClinician = itemClinician.data().program ? itemClinician.data().program : ''
                  if(programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program') {
                    if(doc.data().status === 'inactive') {
                      console.log('2')
                      auth.signOut()
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Your account is disable, contact support to reactivate.`,
                            icon: 'CoffeeIcon',
                            variant: 'warning',
                          },
                        })
                      })
                    } else {
                      // console.log(doc.data().expiredDate)
                      if(doc.data().statusReferral && doc.data().statusReferral === 'inactive') {
                        console.log('3')
                        auth.signOut()
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: `Your account is disable, contact support to reactivate.`,
                              icon: 'CoffeeIcon',
                              variant: 'warning',
                            },
                          })
                        })
                      } else {
                        // if(doc.data().expiredDate) {
                        db.collection('usuarios').doc(signIn.user.uid).collection('details').doc('trackingDetail').get()
                        .then(queryTrack => {
                          if(queryTrack.exists) {
                            if(queryTrack.data().datePickers && queryTrack.data().datePickers[9].value) {
                              var formatDateOrigin = moment(queryTrack.data().datePickers[9].value,'MM/DD/YYYY').format('MM/DD/YYYY')
                              var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                              // var expiredDate = moment(formatDate).add(12, 'months')
                              // var dateUpdateFormat = moment(formatDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                              // var expirationDate = dateUpdateFormat
                              // console.log(this.expirationDate)
                              var usuarioDataExpiredDate = moment(formatDate).format('x')
                              // console.log(usuarioDataExpiredDate)



                              var dateExpired = parseInt(usuarioDataExpiredDate, 10);
                              
                              var day1 = new Date();
                              var day2 = new Date(dateExpired);
                              
                              var difference= day2-day1;
                              var days = difference/(1000 * 3600 * 24);
                              
                              if(Math.trunc(days) <= 0) {
                                auth.signOut()
                                .then(() => {
                                  this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                      title: `Your account is expired, contact support to reactivate.`,
                                      icon: 'CoffeeIcon',
                                      variant: 'warning',
                                    },
                                  })
                                })
                              } else {
                                const UsuarioLogueado = {
                                  email: signIn.user.email,
                                  uid : signIn.user.uid
                                }

                                const userData = doc.data()
                                // console.log(userData)
                                // Construir usuario
                                this.nuevoUsuario(userData)

                                this.$ability.update(userData.ability)

                                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                .then(() => {
                                  this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                      title: `Welcome back ${userData.nombre}`,
                                      icon: 'CoffeeIcon',
                                      variant: 'success',
                                    },
                                  })
                                })
                              }
                            } else {
                              auth.signOut()
                              .then(() => {
                                this.$toast({
                                  component: ToastificationContent,
                                  position: 'top-right',
                                  props: {
                                    title: `Your account is expired, contact support to reactivate.`,
                                    icon: 'CoffeeIcon',
                                    variant: 'warning',
                                  },
                                })
                              })
                            }
                          } else {
                            auth.signOut()
                            .then(() => {
                              this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                  title: `Your account is expired, contact support to reactivate.`,
                                  icon: 'CoffeeIcon',
                                  variant: 'warning',
                                },
                              })
                            })
                          }
                        })
                      }
                      // else {
                      //   // auth.signOut()
                      //   const UsuarioLogueado = {
                      //     email: signIn.user.email,
                      //     uid : signIn.user.uid
                      //   }

                      //   const userData = doc.data()
                      //   // Construir usuario
                      //   this.nuevoUsuario(userData)

                      //   this.$ability.update(userData.ability)

                      //   this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                      //   .then(() => {
                      //     this.$toast({
                      //       component: ToastificationContent,
                      //       position: 'top-right',
                      //       props: {
                      //         title: `Welcome back ${userData.nombre}`,
                      //         icon: 'CoffeeIcon',
                      //         variant: 'success',
                      //       },
                      //     })
                      //   })
                      // }
                    }
                  } else {
                    auth.signOut()
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Your account is disable, contact support to reactivate.`,
                          icon: 'CoffeeIcon',
                          variant: 'warning',
                        },
                      })
                    })
                  }
                })
              } else {
                auth.signOut()
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Your account is disable, contact support to reactivate.`,
                      icon: 'CoffeeIcon',
                      variant: 'warning',
                    },
                  })
                })
              }
            } else {
              if(doc.data().status === 'inactive') {
                console.log('2')
                auth.signOut()
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Your account is disable, contact support to reactivate.`,
                      icon: 'CoffeeIcon',
                      variant: 'warning',
                    },
                  })
                })
              } else {
                // console.log(doc.data().expiredDate)
                if(doc.data().statusReferral && doc.data().statusReferral === 'inactive') {
                  console.log('3')
                  auth.signOut()
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Your account is disable, contact support to reactivate.`,
                        icon: 'CoffeeIcon',
                        variant: 'warning',
                      },
                    })
                  })
                } else {
                  // if(doc.data().expiredDate) {
                  db.collection('usuarios').doc(signIn.user.uid).collection('details').doc('trackingDetail').get()
                  .then(queryTrack => {
                    if(queryTrack.exists) {
                      if(queryTrack.data().datePickers && queryTrack.data().datePickers[9].value) {
                        var formatDateOrigin = moment(queryTrack.data().datePickers[9].value,'MM/DD/YYYY').format('MM/DD/YYYY')
                        var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                        // var expiredDate = moment(formatDate).add(12, 'months')
                        // var dateUpdateFormat = moment(formatDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                        // var expirationDate = dateUpdateFormat
                        // console.log(this.expirationDate)
                        var usuarioDataExpiredDate = moment(formatDate).format('x')
                        // console.log(usuarioDataExpiredDate)



                        var dateExpired = parseInt(usuarioDataExpiredDate, 10);
                        
                        var day1 = new Date();
                        var day2 = new Date(dateExpired);
                        
                        var difference= day2-day1;
                        var days = difference/(1000 * 3600 * 24);
                        
                        if(Math.trunc(days) <= 0) {
                          auth.signOut()
                          .then(() => {
                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                title: `Your account is expired, contact support to reactivate.`,
                                icon: 'CoffeeIcon',
                                variant: 'warning',
                              },
                            })
                          })
                        } else {
                          const UsuarioLogueado = {
                            email: signIn.user.email,
                            uid : signIn.user.uid
                          }

                          const userData = doc.data()
                          // console.log(userData)
                          // Construir usuario
                          this.nuevoUsuario(userData)

                          this.$ability.update(userData.ability)

                          this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                          .then(() => {
                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                title: `Welcome back ${userData.nombre}`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                              },
                            })
                          })
                        }
                      } else {
                        auth.signOut()
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: `Your account is expired, contact support to reactivate.`,
                              icon: 'CoffeeIcon',
                              variant: 'warning',
                            },
                          })
                        })
                      }
                    } else {
                      auth.signOut()
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Your account is expired, contact support to reactivate.`,
                            icon: 'CoffeeIcon',
                            variant: 'warning',
                          },
                        })
                      })
                    }
                  })
                }
                // else {
                //   // auth.signOut()
                //   const UsuarioLogueado = {
                //     email: signIn.user.email,
                //     uid : signIn.user.uid
                //   }

                //   const userData = doc.data()
                //   // Construir usuario
                //   this.nuevoUsuario(userData)

                //   this.$ability.update(userData.ability)

                //   this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                //   .then(() => {
                //     this.$toast({
                //       component: ToastificationContent,
                //       position: 'top-right',
                //       props: {
                //         title: `Welcome back ${userData.nombre}`,
                //         icon: 'CoffeeIcon',
                //         variant: 'success',
                //       },
                //     })
                //   })
                // }
              }
            }
          } else if(doc.data().role === 'staff') {
            // if(doc.data().status === 'inactive') {
            //   auth.signOut()
            //   .then(() => {
            //     this.$toast({
            //       component: ToastificationContent,
            //       position: 'top-right',
            //       props: {
            //         title: `Your account is disable, contact support to reactivate.`,
            //         icon: 'CoffeeIcon',
            //         variant: 'success',
            //       },
            //     })
            //   })
            // } else {
              const UsuarioLogueado = {
                email: signIn.user.email,
                uid : signIn.user.uid
              }

              const userData = doc.data()
              // Construir usuario
              this.nuevoUsuario(userData)

              this.$ability.update(userData.ability)

              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome back ${userData.nombre}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
              })
            // }
          } else {
            const UsuarioLogueado = {
              email: signIn.user.email,
              uid : signIn.user.uid
            }

            const userData = doc.data()
            // Construir usuario
            this.nuevoUsuario(userData)

            this.$ability.update(userData.ability)

            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome back ${userData.nombre}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: "User does not exist",
            },
          })
        }
      } catch(error) {
        if(error.code === "auth/wrong-password") {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: "Incorrect password.",
            },
          })
        } else if(error.code === "auth/user-not-found") {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: "Incorrect email or password.",
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Incorrect email or password.',
            },
          })
        }
      }
    },
  },
  // created() {
  //   localStorage.removeItem('logActy')
  // }
}
</script>

<style>
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.top-nav-login {
  background-color: #94221e;
  /* background-image: url(/assets/images/pattern-8d0f59e0f30b8e1261b9e36e843460eb.png); */
  /* background-repeat: repeat; */
  border: none;
  height: 65px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.brand-logo-svg{fill: white;height: 38px;}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  border-radius: 4px;
  width: 450px
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.text-card-register {
  font-size: 15px;
}
</style>
